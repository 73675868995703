"use client";

import AdminTokenSelector from '@/components/AdminTokenSelector';
import AdminGameSelector from '@/components/DropdownGameSelector';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { Game, Token } from '@/lib/schema';
import { getConversionData } from '@/lib/utils';
import { CreateTournamentProps, handleCreateNewTournament } from '@/server-actions/community-create-tournament';
import { useUser } from '@clerk/nextjs';
import { sendGAEvent } from '@next/third-parties/google';
import { HelpCircle, Loader2Icon } from 'lucide-react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { erc20Abi, parseUnits } from 'viem';
import { useReadContract } from 'wagmi';
import { ColorPicker } from './ColorPicker';
import { DateTimePicker } from './DateTimePicker';
import RequestTokenModal from './RequestTokenModal';
import { TournamentTooltip } from './Tooltip';
import TournamentCreatedModal from './TournamentCreatedModal';
import { Checkbox } from './ui/checkbox';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';

type TierState = string[];

function CreateTournament({ games, tokens, admin }: { games: Game[], tokens: Token[], admin?: boolean }) {
    // sort tokens with MCADE on top
    tokens.sort((a, b) => a.symbol === "MCADE" ? -1 : b.symbol === "MCADE" ? 1 : 0)
    const defaultToken = tokens.find(token => token.symbol === "MCADE")

    const { toast } = useToast();
    const { user } = useUser();

    const [gatedTournament, setGatedTournament] = useState<boolean>(false)
    const [blindLeaderboard, setBlindLeaderboard] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false);
    const [communityName, setCommunityName] = useState<string>('')
    const [communitySocial, setCommunitySocial] = useState<string>('')
    const [createCompleted, setCreateCompleted] = useState(false);
    const [tournamentId, setTournamentId] = useState<number | null>(null);
    const [prizeSpread, setPrizeSpread] = useState<number>(5);
    const [tiers, setTiers] = useState<TierState>([
        "30", // $0.10~
        "300", // $1~
        "1500" // $5~
    ]);
    const [selectedToken, setSelectedToken] = useState<string>(defaultToken?.address || '')
    const [selectedTokenDecimals, setSelectedTokenDecimals] = useState<number>(defaultToken?.decimals || 18)
    const [selectedGame, setSelectedGame] = useState<string>('')
    const [selectedNetwork, setSelectedNetwork] = useState<string>('BASE')
    const [tokenRequestModalOpen, setTokenRequestModalOpen] = useState<boolean>(false)
    const [conversionRate, setConversionRate] = useState<number>(0)
    // timestamp 7 days from now
    const oneWeekFromNow = new Date();
    oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
    const [date, setDate] = useState<Date>(oneWeekFromNow)
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [selectedLogo, setSelectedLogo] = useState<string>('');
    const [primaryColorOpen, setPrimaryColorOpen] = useState(false);
    const [secondaryColorOpen, setSecondaryColorOpen] = useState(false);

    // Add this function to check if the selected date is valid
    const isDateValid = (selectedDate: Date): boolean => {
        const now = new Date();
        const oneHourFromNow = new Date(now.getTime() + 1 * 60 * 60 * 1000);
        return selectedDate >= oneHourFromNow;
    };

    const { data: userBalance } = useReadContract({
        abi: erc20Abi,
        address: selectedToken as `0x${string}`,
        functionName: 'balanceOf',
        query: {
            enabled: Boolean(selectedToken && user?.unsafeMetadata.wallet),
        },
        args: [user?.unsafeMetadata.wallet as `0x${string}`]
    })

    const logos = [
        "https://api.metafuse.me/assets/metacade/metacade.webp",
        "https://api.metafuse.me/assets/metacade/game-assets/based_cartel.jpg",
        "https://api.metafuse.me/assets/metacade/game-assets/babyneiro.jpg",
        "https://api.metafuse.me/assets/metacade/game-assets/catalorian.jpg",
        "https://api.metafuse.me/assets/metacade/game-assets/brett.png"
    ]

    // Add this function to calculate and format the USD equivalent
    const calculateUSDEquivalent = (tokenAmount: string): string => {
        const amount = parseFloat(tokenAmount);
        if (isNaN(amount) || conversionRate === 0) return 'N/A (too low)';
        const usdValue = amount * conversionRate;
        return `$${usdValue.toFixed(2)} USD`;
    };

    useEffect(() => {
        if (!user || selectedLogo) return;
        setSelectedLogo(user.imageUrl || '');
    }, [user])

    useEffect(() => {
        const fetchConversionRate = async () => {
            if (selectedToken && tiers[0]) {
                const valueAsWei = parseUnits(tiers[0], 18);
                const rate = await getConversionData(selectedToken, valueAsWei.toString());
                setConversionRate(rate.pricePerToken);
            }
        };

        fetchConversionRate();
    }, [selectedToken]);

    const handleTierChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const newTiers = [...tiers];
        newTiers[index] = newValue;

        if (newValue !== '') {
            const numericValue = parseInt(newValue, 10);
            if (!isNaN(numericValue) && numericValue > 0) {
                // Adjust other tiers based on the changed tier
                if (index === 0) {
                    // If tier 1 (1 credit) changed, adjust tier 2 and 3
                    newTiers[1] = (numericValue * 10).toString();
                    newTiers[2] = (numericValue * 50).toString();
                } else if (index === 1) {
                    // If tier 2 (10 credits) changed, adjust tier 1 and 3
                    newTiers[0] = Math.max(1, Math.round(numericValue / 10)).toString();
                    newTiers[2] = (numericValue * 5).toString();
                } else if (index === 2) {
                    // If tier 3 (50 credits) changed, adjust tier 1 and 2
                    newTiers[0] = Math.max(1, Math.round(numericValue / 50)).toString();
                    newTiers[1] = Math.max(1, Math.round(numericValue / 5)).toString();
                }
            }
        }

        setTiers(newTiers);

        // Debounce the conversion rate update
        const debounceTimer = setTimeout(async () => {
            if (selectedToken && newValue) {
                console.log(`Getting conversion rate for ${selectedToken} with value ${newValue}`);
                const valueAsWei = parseUnits(newValue, 18);
                const rate = await getConversionData(selectedToken, valueAsWei.toString());
                console.log(`Conversion rate: ${JSON.stringify(rate)}`);
                setConversionRate(rate.pricePerToken);
            }
        }, 500);

        // Clear the timeout if the component unmounts or the function is called again
        return () => clearTimeout(debounceTimer);
    };

    const handlePrizeSpreadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue === '') {
            setPrizeSpread(0);
        } else {
            const numericValue = parseInt(newValue, 10);
            if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 50) {
                setPrizeSpread(numericValue);
            }
        }
    };

    const handleGatedTournamentChange = (checked: boolean) => {
        setGatedTournament(checked);
    };

    const handleBlindLeaderboardChange = (checked: boolean) => {
        setBlindLeaderboard(checked);
    };

    const handleCreateTournament = async () => {
        try {
            console.log(`User balance: ${userBalance}`)
            if (!userBalance || userBalance === 0n) {
                toast({
                    title: 'Insufficient Balance',
                    description: `You are required to have some of the token used for credits to create a tournament.`,
                });
                return;
            }

            if (!admin && tiers.some(tier => tier === '0')) {
                toast({
                    title: 'Invalid Credit Tiers',
                    description: 'All Credit tiers must be greater than 0.',
                });
                return;
            }

            setIsLoading(true);

            const creditPackages = { tier1: tiers[0], tier2: tiers[1], tier3: tiers[2] };
            const requestInput: CreateTournamentProps = {
                gameId: +selectedGame,
                logoUrl: selectedLogo,
                primaryColor: primaryColor,
                creatorUsername: admin ? 'Metacade' : user?.username || '',
                secondaryColor: secondaryColor,
                network: selectedNetwork,
                paymentTokenDecimals: selectedTokenDecimals,
                tokenContractAddress: selectedToken,
                creditPackages,
                communityName: communityName,
                communitySocial: communitySocial,
                endTime: date.toISOString(),
                gatedByWalletAllowList: gatedTournament,
                prizeSpread: prizeSpread,
                blindLeaderboard: blindLeaderboard
            };
            const result = await handleCreateNewTournament({
                createTournamentProps: requestInput
            });

            // Assuming the result includes the new tournament ID
            setTournamentId(result.tournamentId);

            toast({
                title: 'Success',
                description: `Successfully created tournament`,
            });
            sendGAEvent('create_community_tournament', {
                wallet: user?.unsafeMetadata.wallet, email: user?.primaryEmailAddress?.emailAddress || "", username: user?.username, gameName: result.gameName, token: selectedToken
            });

            setCreateCompleted(true);
        } catch (error) {
            console.error(error);
            toast({
                title: 'Error',
                description: 'Failed to create tournament',
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (createCompleted) {
        return <TournamentCreatedModal tournamentId={tournamentId?.toString() || ''} selectedNetwork={selectedNetwork} gameId={selectedGame} />
    }

    return (
        <div className="flex flex-col py-4 space-y-4 max-h-[80vh] overflow-y-auto">
            <div className="flex flex-col space-y-6 mt-4 text-left">
                <div className='flex items-center'>
                    <Label htmlFor="game" className="w-24 text-left">
                        Game
                    </Label>
                    <AdminGameSelector games={games} selectedGame={selectedGame} setGame={setSelectedGame} />
                </div>
                <div className='flex items-center'>
                    <Label htmlFor="token" className="w-24 text-left">
                        Token
                    </Label>
                    <AdminTokenSelector tokens={tokens} selectedToken={selectedToken} setToken={setSelectedToken} selectedNetwork={selectedNetwork} setSelectedTokenDecimals={setSelectedTokenDecimals} />
                    <TournamentTooltip text='Want to add your own token? Click here to submit a request.'>
                        <HelpCircle className="text-white cursor-pointer hover:animate-pulse ml-2" height={25} width={25} onClick={() => setTokenRequestModalOpen(true)} />
                    </TournamentTooltip>
                    {tokenRequestModalOpen && <RequestTokenModal setTokenRequestModalOpen={setTokenRequestModalOpen} tokenRequestModalOpen={tokenRequestModalOpen} >
                        <HelpCircle className="text-white cursor-pointer hover:animate-pulse" height={25} width={25} onClick={() => setTokenRequestModalOpen(true)} />
                    </RequestTokenModal>}
                </div>
                {admin && (
                    <>
                        <div className='flex items-center'>
                            <Label htmlFor="community-logo" className="w-24 text-left">
                                Community Logo
                            </Label>
                            <Select onValueChange={setSelectedLogo} value={selectedLogo}>
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Select a logo" />
                                </SelectTrigger>
                                <SelectContent>
                                    {logos.map((logo, index) => (
                                        <SelectItem key={index} value={logo}>
                                            <Image src={logo} alt="Logo" width={32} height={32} />
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='flex items-center'>
                            <Label htmlFor="community-name" className="w-24 text-left">
                                Community Name
                            </Label>
                            <Input id="community-name" className='max-w-[200px]' value={communityName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCommunityName(event.target.value)} />
                        </div>
                        <div className='flex items-center'>
                            <Label htmlFor="community-socials" className="w-24 text-left">
                                Community X URL
                            </Label>
                            <Input id="community-socials" className='max-w-[200px]' value={communitySocial} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCommunitySocial(event.target.value)} />
                        </div>
                        <div className='flex items-center z-50'>
                            <Label htmlFor="primary-color" className="w-24 text-left">
                                Primary Color
                            </Label>
                            <ColorPicker key={`cp-1`} value={primaryColor} onChange={setPrimaryColor} open={primaryColorOpen} setOpen={setPrimaryColorOpen} />
                        </div>
                        <div className='flex items-center z-530'>
                            <Label htmlFor="secondary-color" className="w-24 text-left">
                                Secondary Color
                            </Label>
                            <ColorPicker key={`cp-2`} value={secondaryColor} onChange={setSecondaryColor} open={secondaryColorOpen} setOpen={setSecondaryColorOpen} />
                        </div>
                    </>
                )}
                <div className='flex flex-col space-y-2'>
                    <Label htmlFor="credit-tiers" className="text-sm">
                        Credit Tiers (<span className="text-xs">1 Game Play = 1 Credit</span>)
                    </Label>
                    <div className='flex items-center'>
                        <div className='flex space-x-2 flex-grow'>
                            <div className='flex flex-col w-1/3'>
                                <span className="text-xs mb-1">1 Credit</span>
                                <Input
                                    id="tiers-1"
                                    value={tiers[0]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTierChange(0, event)}
                                />
                                <span className="text-xs mt-1 text-gray-400">{calculateUSDEquivalent(tiers[0])}</span>
                            </div>
                            <div className='flex flex-col w-1/3'>
                                <span className="text-xs mb-1">10 Credits</span>
                                <Input
                                    id="tiers-2"
                                    value={tiers[1]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTierChange(1, event)}
                                />
                                <span className="text-xs mt-1 text-gray-400">{calculateUSDEquivalent(tiers[1])}</span>
                            </div>
                            <div className='flex flex-col w-1/3'>
                                <span className="text-xs mb-1">50 Credits</span>
                                <Input
                                    id="tiers-3"
                                    value={tiers[2]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTierChange(2, event)}
                                />
                                <span className="text-xs mt-1 text-gray-400">{calculateUSDEquivalent(tiers[2])}</span>
                            </div>
                        </div>
                        <TournamentTooltip text='The values here are the amount of tokens that are required to purchase an amount of credits. Eg 20 tokens = 1 credit. Minimum is 0.20 USD per credit.'>
                            <HelpCircle className="text-white ml-2" height={25} width={25} />
                        </TournamentTooltip>
                    </div>
                </div>
                <div className='flex items-center'>
                    <Label htmlFor="tournament-end" className="w-24 text-left">
                        Prize Spread
                    </Label>
                    <div className='flex items-center'>
                        <Input id="prize-spread" value={prizeSpread} onChange={handlePrizeSpreadChange} />
                        <TournamentTooltip text='The amount of positions that the prize is distributed to. Eg, top 5 players are eligible for the prize pool.'>
                            <HelpCircle className="text-white ml-2" height={25} width={25} />
                        </TournamentTooltip>
                    </div>
                </div>
                <div className='flex items-center'>
                    <Label htmlFor="gated-tournament" className="w-24 text-left">
                        Gated Tournament
                    </Label>
                    <div className='flex items-center'>
                        <Checkbox
                            id="gated-tournament"
                            checked={gatedTournament}
                            onCheckedChange={handleGatedTournamentChange}
                        />
                        <TournamentTooltip text='You will be able to define an allow list of who can join the tournament after it is created.'>
                            <HelpCircle className="text-white ml-2" height={20} width={20} />
                        </TournamentTooltip>
                    </div>
                </div>
                <div className='flex items-center'>
                    <Label htmlFor="gated-tournament" className="w-24 text-left">
                        Blind Leaderboard
                    </Label>
                    <div className='flex items-center'>
                        <Checkbox
                            id="blind-leaderboard"
                            checked={blindLeaderboard}
                            onCheckedChange={handleBlindLeaderboardChange}
                        />
                        <TournamentTooltip text='The leaderboard will be hidden until the tournament ends.'>
                            <HelpCircle className="text-white ml-2" height={20} width={20} />
                        </TournamentTooltip>
                    </div>
                </div>
                <div className='flex items-center'>
                    <Label htmlFor="tournament-end" className="w-24 text-left">
                        Tournament End
                    </Label>
                    <DateTimePicker date={date} setDate={setDate} />
                    {!isDateValid(date) && (
                        <span className="text-red-500 ml-2 text-sm">
                            Date must be at least 1 hour in the future
                        </span>
                    )}
                </div>
                {!admin && (
                    <div className='flex items-center'>
                        <Label htmlFor="conversion-rate" className="w-24 text-left">
                            Conversion Rate
                        </Label>
                        <div className='flex items-center'>
                            {
                                <span>{parseFloat(conversionRate.toString()).toFixed(7)} USD per token</span>
                            }
                            <TournamentTooltip text='The current conversion rate from the selected token to USD.'>
                                <HelpCircle className="text-white ml-2" height={25} width={25} />
                            </TournamentTooltip>
                        </div>
                    </div>
                )}
                <div className='space-x-2 flex justify-center'>
                    <Button
                        onClick={async () => await handleCreateTournament()}
                        disabled={isLoading || (!admin && tiers.some(tier => tier === '')) || !selectedToken || !selectedGame || (!admin && !selectedNetwork) || (!admin && prizeSpread === 0) || !isDateValid(date) || (admin && !selectedLogo)}
                        className="w-full min-w-[150px]"
                    >
                        {isLoading ? (
                            <>
                                <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                                Creating...
                            </>
                        ) : conversionRate === 0 ? "Create Free Tournament" : "Create"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CreateTournament;
